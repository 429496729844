<template>
  <div>
    <!-- Header -->
    <div class="header-bar">
      <!-- Logo -->
      <router-link to="/" class="logo-router">
        <img src="/images/logo.svg" alt="logo" class="logo" />
      </router-link>

      <!-- Navigation -->
      <div class="navigation-container">
        <IconLinkNavigation to="/schedule" icon="/images/schedule.png" label="schedule" />
        <IconLinkNavigation to="/leaderboard" icon="/images/leaderboard.png" label="leaderboard" />
      </div>
    </div>


    <!-- Content -->
    <div class="content">
      <router-view />
    </div>

    <!-- Footer -->
    <div class="footer-bar">
      <span class="footer-text">API Version: {{ apiVersion }}</span>
    </div>
  </div>
</template>

<style>
.header-bar {
  position: sticky;
  top: 0;
  height: 60px;
  width: calc(100% - 2 * var(--header-x-gap));;
  background-color: var(--header-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--header-x-gap);
}

.navigation-container {
  display: flex;
  gap: var(--header-x-gap);
}

.logo-router {
  display: grid;
  place-items: center;
}

.logo {
  width: 110px;
}

.content {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.footer-bar {
  position: sticky;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  background-color: var(--footer-color);
  display: flex;
  align-items: center;
  justify-content: flex-end;

}
.footer-text {
  padding-right: var(--footer-padding);
  color: #4B5C68;
  font-weight: 700;
}
</style>

<script setup>
import { ref, onMounted } from 'vue';

import api from '../services/api'

import IconLinkNavigation from '../components/Navigation/IconLinkNavigation.vue'

const apiVersion = ref('')
const getAPIVersion = async () => {
  const { data } = await api.get('/version')
  apiVersion.value = data.version
}

onMounted(() => {
  getAPIVersion()
})
</script>